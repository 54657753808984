<script setup lang="ts">
import { lightTheme } from "./styles/light"
import { darkTheme } from "./styles/dark"
import { useStorage } from "@vueuse/core"
import { antdMessages } from "@/utils/i18n"
import styleInject from "./components/styleInject.vue"
import vncModel from "./components/vncModel.vue"
const themeKey = useStorage("THEME", "light")
const locale = useStorage("LOCALE", "zh-cn")
themeKey.value = "light"
const theme = computed(() => {
  // const theme = themeKey.value == "dark" ? darkTheme : lightTheme
  const theme = lightTheme
  return theme
})
type localType = keyof typeof antdMessages
const locales = computed(() => {
  return antdMessages[locale.value as localType]
})

console.log(import.meta.env.MODE)
console.log(process.env.NODE_ENV)
console.log(import.meta.env.VITE_HOST)
</script>

<template>
  <a-config-provider :theme="theme" :locale="locales" :dropdownMatchSelectWidth="false">
    <styleInject />
    <router-view></router-view>
    <use-loading />
    <vnc-model />
  </a-config-provider>
</template>
