import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import { i18n } from "@/utils/i18n/index"
const t = i18n.global.t
import layout from "@/layouts/index-left.vue"
import { getToken, getUserInfo } from "@/utils"
export type role = "admin" | "dealer" | "end_user"

export type RowMeta = {
  meta: {
    title: string
    roles: Array<role>
    hidden?: boolean
    icon?: string
  }
  children?: Array<RowMeta>
} & RouteRecordRaw

export const whiteRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    meta: { title: t("login.login") },
    beforeEnter: (to, from, next) => {
      //https://hdd.vidagrid.com/#/login?templateid=497
      //https://hdd.vidagrid.com/#/login?forgot=1
      if (getToken() && to.query.templateid) {
        next(`/device/templatelist?templateid=${to.query.templateid}`)
      } else {
        next()
      }
    },
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/scada",
    name: "scada",
    meta: { title: t("dashboard.config") },
    component: () => import("@/views/scada.vue")
  },
  {
    path: "/register",
    name: "register",
    meta: { title: t("register.register") },
    component: () => import("@/views/register/index.vue")
  },
  {
    path: "/serviceagreement/cn",
    name: "serviceagreementcn",
    meta: { title: t("login.serviceAgreement") },
    component: () => import("@/views/agreement/service/index_cn.vue")
  },
  {
    path: "/activeresult",
    name: "activeResult",
    meta: { title: t("common.activeResult") },
    component: () => import("@/views/activeResult.vue")
  },
  {
    path: "/serviceagreement/cn",
    name: "serviceagreementcn",
    meta: { title: t("login.serviceAgreement") },
    component: () => import("@/views/agreement/service/index_cn.vue")
  },
  {
    path: "/privacyagreement/cn",
    name: "privacyagreementcn",
    meta: { title: t("login.privacyAgreement") },
    component: () => import("@/views/agreement/privacy/index_cn.vue")
  },
  {
    path: "/serviceagreement/en",
    name: "serviceagreementen",
    meta: { title: t("login.serviceAgreement") },
    component: () => import("@/views/agreement/service/index_en.vue")
  },
  {
    path: "/privacyagreement/en",
    name: "privacyagreementen",
    meta: { title: t("login.privacyAgreement") },
    component: () => import("@/views/agreement/privacy/index_en.vue")
  },
  {
    path: "/appdownload",
    name: "appdownload",
    component: () => import("@/views/appdownload/index.vue"),
    meta: { title: t("common.appDownload"), roles: ["admin", "dealer", "end_user"] }
  }
]

export const authRoutes: Array<RowMeta> = [
  {
    path: "/",
    name: "index",
    component: layout,
    redirect: "/dashboard",
    meta: { title: "", roles: ["admin", "dealer", "end_user"] },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta: { title: t("dashboard.dashboard"), icon: "icon-shujukanban", roles: ["admin", "dealer", "end_user"] }
      },
      {
        path: "/device",
        name: "device",
        redirect: "/device/devicelist",
        meta: { title: t("device.deviceManage"), icon: "icon-shebeiguanli", roles: ["admin", "dealer", "end_user"] },
        children: [
          {
            path: "devicelist",
            name: "devicelist",
            component: () => import("@/views/device/deviceList/index.vue"),
            meta: { title: t("device.deviceList"), roles: ["admin", "dealer", "end_user"] }
          },
          {
            path: "templatelist",
            name: "templatelist",
            component: () => import("@/views/device/templateList/index.vue"),
            meta: { title: t("device.templateList"), roles: ["admin", "dealer", "end_user"] }
          },
          {
            path: "devicegroup",
            name: "devicegroup",
            component: () => import("@/views/device/deviceGroup/index.vue"),
            meta: { title: t("device.deviceGroup"), roles: ["admin", "dealer", "end_user"] }
          }
        ]
      },
      {
        path: "/billing",
        name: "billing",
        redirect: "/billing/myAccount",
        meta: { title: t("billing.billingManage"), icon: "icon-jifeiguanli", roles: ["admin", "dealer", "end_user"] },
        children: [
          {
            path: "myAccount",
            name: "myAccount",
            component: () => import("@/views/billing/myAccount/index.vue"),
            meta: { title: t("billing.myAccount"), roles: ["admin", "dealer", "end_user"] }
          },
          {
            path: "rechargeRecord",
            name: "rechargeRecord",
            component: () => import("@/views/billing/rechargeRecord/index.vue"),
            meta: { title: t("billing.rechargeRecords"), roles: ["admin", "dealer", "end_user"] }
          }
        ]
      },
      // {
      //   path: "/message",
      //   name: "message",
      //   redirect: "/message/alarm",
      //   meta: { title: t("message.messageCenter"), icon: "icon-xiaoxizhongxin", roles: ["admin", "dealer", "end_user"] },
      //   children: [
      //     {
      //       path: "alarm",
      //       name: "alarm",
      //       component: () => import("@/views/message/alarm/index.vue"),
      //       meta: { title: t("message.alarm"), roles: ["admin", "dealer", "end_user"] }
      //     },
      //     {
      //       path: "setting",
      //       name: "setting",
      //       component: () => import("@/views/message/setting/index.vue"),
      //       meta: { title: t("message.setting"), roles: ["admin", "dealer", "end_user"] }
      //     }
      //   ]
      // },
      // {
      //   path: "/permission",
      //   name: "permission",
      //   redirect: "/permission/role",
      //   meta: { title: t("permission.permissionManage"), icon: "icon-quanxianguanli", roles: ["admin", "dealer", "end_user"] },
      //   children: [
      //     {
      //       path: "role",
      //       name: "role",
      //       component: () => import("@/views/permission/role/index.vue"),
      //       meta: { title: t("permission.role"), roles: ["admin", "dealer", "end_user"] }
      //     },
      //     {
      //       path: "user",
      //       name: "user",
      //       component: () => import("@/views/permission/user/index.vue"),
      //       meta: { title: t("permission.user"), roles: ["admin", "dealer", "end_user"] }
      //     }
      //   ]
      // },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/index.vue"),
        meta: { title: t("profile.personCenter"), icon: "icon-gerenzhongxin", roles: ["admin", "dealer", "end_user"] }
      }
    ]
  }
]

export const errRoute: RouteRecordRaw = {
  path: "/:pathMatch(.*)*",
  name: "notfound",
  redirect: "/",
  meta: { title: "404" }
}

// let indx = 0
// const findBilling = authRoutes[0].children?.some((item, index) => {
//   if (item.name == "billing" && import.meta.env.PROD) {
//     indx = index
//     return true
//   }
// })
// if (findBilling) {
//   authRoutes[0].children?.splice(indx, 1)
// }
console.log(authRoutes)

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...whiteRoutes, ...authRoutes, errRoute]
})

const whiteNames = whiteRoutes.map((item) => item.name)

router.beforeEach(async (to, from, next) => {
  window.$loading.start()
  const token = getToken()
  const userInfo = getUserInfo()

  if (whiteNames.includes(to.name as string)) {
    return next()
  }
  if (!token || !userInfo) {
    return next("/login")
  }

  next()
})

router.afterEach((to) => {
  window.$loading.finish()
  document.title = `${to.meta.title}`
})

export default router
