import { reject } from "lodash-es"

export default {
  common: {
    accountSetting: "Account Settings",
    passwordSetting: "Password Settings",
    pi: "Please input {0}",
    ps: "Please select {0}",
    and: "and",
    account: "Account",
    verificationCode: "Verification Code",
    password: "Password",
    currentPassword: "Old Password",
    goLogin: "Login with existing account?",
    confirm: "Confirmed",
    confirm2: "Confirmed",
    cancel: "Cancel",
    confirmPassword: "Confirm Password",
    confirmSettings: "Confirm Settings",
    newPassowrd: "New Password",
    userName: "User Name",
    dataCenter: "Data Center",
    appDownload: "Application Download",
    mail: "Email",
    europe: "Europe",
    theUnitedStates: "The United States",
    australia: "Australia",
    china: "China",
    india: "India",
    phone: "Mobile",
    len: "The minimal length of {0} is {1}",
    registerSuccessConfirm: "Registration finished, login now?",
    registerSuccessPLogin: "Account created. Please login.",
    confirmDiff: "Passwords inputed are inconsistent",
    newPwdSame: "The new password should be different from the old password.",
    newPwdDiff: "The password confirmed is inconsistent with the new password.",
    passwordFormatError: "8-16 characters, containing at least two or more numbers, letters or symbols.",
    phoneAndMailFormatError: "The Mobile/Email address inputed is incorrect.",
    phoneFormatError: "The mobile inputed is incorrect.",
    mailFormatError: "The email address inputed is incorrect.",
    successfully: "completed",
    close: "Close",
    operation: "Operation",
    query: "Search",
    pageTotal: "Total:  {0}",
    submit: "Submit",
    min: "Please input at least {0} characters",
    max: "Please input less than {0} characters",
    minMaxLimit: "Please input {0} to {1} characters",
    basic: "Basic",
    tag: "TAG",
    screen: "Dashboard Screen",
    alarm: "Alarm",
    loginSuccessfully: "Login completed.",
    logoutSuccessfully: "Logout completed.",
    read: "Read",
    write: "Write",
    readWrite: "Read/Write",
    edit: "Edit",
    delete: "Delete",
    tip: "Tips",
    updateSuccessfully: "Update completed!",
    alarmLevel1: "Urgent",
    alarmLevel2: "Low",
    alarmLevel3: "Normal",
    online: "Online",
    offline: "Offline",
    logout: "Logout",
    settings: "Settings",
    colorScheme: "Color Scheme",
    menuScheme: "Menu",
    lightMode: "Day Mode",
    darkMode: "Night Mode",
    light: "Light",
    dark: "Dark",
    operationSuccessfully: "Operation completed.",
    valName: "Variable Name",
    create: "Create",
    yes: "Yes",
    no: "No",
    getUserInfoFaild: "Failed to get the user information, please login again.",
    switchToNew: "Please complete the basic user information",
    switchToNewMsg: "Submitted completed.",
    resetPassword: "Reset password",
    permission: "Privileges",
    roleName: "Role name",
    unbindCurrDevice: "Unbind the device",
    unbindCurrDeviceMemo: "If the device is deleted, the data generated by this device will be permanently removed.",
    changePasswordSuccessfully: "The password is changed, please login again.",
    changeProfileSuccessfully: "The user information is changed successfully.",
    noData: "No data",
    backToLogin: "Back to Login page",
    mailResultMemo1: "Thanks for registering, an activation email has been sent to your email account.",
    mailResultMemo2:
      "Please check the activation email and finish the registration process as instructed within 48 hours.",
    mailResultMemo3: "No activation code received?",
    mailResultMemo4: "Please check the email is moved to the advertisement or junk email folder.",
    mailResultMemo5: "Retry if no email is received.",
    reSend: "Resend",
    activeMail: "activation email",
    moveTo: "Move to...",
    groupPickerMemo1: "Move to any group",
    groupPickerMemo2: "Please select the group",
    deleteTemplate: "Delete the template",
    deleteTemplateConfirm: "Do you really want to remove the template",
    activeResult: "Activation result",
    dataCenterPickerMemo1: "Your device data will be stored in the selected data center, please choose it carefully",
    templateLimit: "Templates reached maximum limit"
  },
  login: {
    loginPlatform: "Login to DIACloud Platform",
    account: "Account",
    password: "Password",
    rememberPassword: "Remember Me",
    phoneAndMailAndUsername: "Mobile/Email",
    readAndAgree: "I've read and accepted",
    serviceAgreement: "Service Agreement",
    privacyAgreement: "Privacy Policy",
    registerNow: "Register now",
    forgotPassword: "Forgot Password",
    login: "Login",
    goLogin: "Login",
    agreeMemo: "Please read and agree to the Service Agreement and Privacy Policy.",
    cookieTips1: "This website uses cookies",
    cookieTips2:
      "We use cookies to improve your experience on your website, we respect your choices and are committed to providing you with a safe browsing experience.",
    reject: "Reject",
    accept: "Accept"
  },
  register: {
    registerWay: "Registeration Options",
    register: "Registeration",
    phoneRegistration: "Register with Mobile",
    mailRegistration: "Register with Email",
    submit: "Submit",
    getVarifyCode: "Send",
    second: "S",
    sendToMuch: "Too many times tried.",
    contactMemo: "Used for messaging devices - receiving channel information, not used for account login"
  },
  dashboard: {
    dashboard: "Data Dashboard",
    collectionEmpty: "My favorites are empty ",
    goto: "Go to",
    download: "download",
    favoriteDevice: "My favorites",
    deviceOverview: "Device Overview",
    hmiScreen: "HMI Screen",
    config: "Dashboard Screen",
    thumbnail: "Thumbnail",
    viewDcreen: "View Dashboard Screen",
    editScreen: "Edit Dashboard Screen",
    configScreen: "Dashboard Screen",
    billingDetail: "Billing Details",
    hmiBind: "Serial Number",
    basicInfo: "Basic Information",
    configUpdate: "Dashboard Screen Update",
    hmiScreenSN: "HMI SN",
    hmiGatewaySN: "HMI Gateway SN",
    deviceName: "Device Name",
    deviceSN: "Device SN",
    deviceModel: "Device Model",
    deviceGroup: "Device Group",
    relateTemplate: "Template",
    deviceAddress: "Device Address",
    remark: "Remarks",
    configCreate: "Created",
    configModify: "Edited",
    maskSuccessfully: "Added to My Favorites!",
    removeMaskSuccessfully: "Removed from My Favorites",
    editModelMemo: "Changes are saved yet, abandon the changes and switch the tag?",
    notAssociated: "Not associated",
    editScreenMsg1: "Please use DIAWeb Designer to edit the screen.",
    editScreenMsg2: "If DIAWeb Designer is not installed on your computer, please "
  },
  device: {
    unBindDevice: "Delete the device",
    unBindDeviceOk: "Delete the device completed",
    deviceManage: "Device Management",
    deviceList: "Device List",
    templateList: "Template List",
    deviceGroup: "Device Groups",
    tunnel: "Tunnel Status",
    groupSameName: "Duplicate group name",
    rename: "Rename",
    addGroup: "Add Group",
    addChildGroup: "Add Sub-Group",
    delete: "Delete",
    deleteSucccessful: "Delete {0} successfully",
    groupName: "Group Name",
    defaultGroup: "Default Group",
    setDefaultGroup: "Set Default Group",
    templateName: "Template Name",
    hmiModel: "HMI Model",
    deviceCount: "Device Number",
    publishTime: "Time Created",
    queryNamePh: "Template Name",
    queryModelPh: "HMI Model",
    addTemplate: "Add Template",
    copyTemplate: "Clone Template",
    deviceName: "Device Name",
    deviceSN: "Device SN",
    description: "Customer Name",
    deviceModel: "Device Model",
    hmiScreenModel: "HMI Model",
    cloneTemplate: "Clone Template",
    remark: "Remarks",
    templateInfo: "Template Information",
    hmiScreen: "HMI Screen",
    fw: "Firmware",
    recipe: "Recipe",
    versionNumber: "Version Number",
    createTime: "Created",
    configurationState: "Dashboard Screen",
    configurationStateCreateTime: "Created",
    configurationStatemodifyTime: "Edited",
    tagName: "TAG Name",
    dataType: "Data Type",
    addrType: "Address Type",
    address: "Address",
    rwType: "Read/Write Type",
    scanTime: "Scan Interval",
    saveHistroy: "Save History Data",
    deviceNameAndTemplateName: "Device Name/Template",
    alarmLevel: "Alarm Level",
    hmiDongleState: "Online Status",
    hmiGraph: "HMI Screen",
    hmiDongleAsync: "Synchronization",
    deviceTag: "Device Tag",
    tagEmpty1: "No tags yet,",
    createTag: "Create Tag",
    editTag: "Edit Tag",
    deleteTag: "Delete Tag",
    addTag: "Add Tag",
    tagNumLimit: "The maximum number of labels is 50",
    checkTagNumLimit: "The maximum number of selected tags is 10",
    createAndSearchTag: "Create/Search",
    addDevice: "Add device",
    syncState1: "Synchronized",
    syncState0: "Synchronizing",
    cloudLastVer: "Cloud Version",
    hmiThisVer: "HMI Version",
    asyncTooltipTitle:
      "Synchronization Status (Please open system update settings on HMI device to conduct synchronization)",
    labelOver: "TAG length should be between 1 and 50 characters",
    labelSame: "TAG name already exists",
    labelNumLimit: "The number of tags reaches the upper limit of 50",
    deviceChangeGroup: "Change Group",
    changeGroupSuccessfully: "Group changed.",
    addModel: "Add model",
    templateDesign: "Dashboard Screen Design",
    unbindLabel: "Delete Tag",
    unbindLabelConfirm:
      "Deleting this tag will also delete tags with the same name that have been bound to other devices. Are you sure you want to delete it?",
    templateDesignConfirm: "Whether to edit the template {0} that the device has joined?"
  },
  template: {
    defaultValue: "Default Value",
    description: "Description",
    used: "Used",
    templateMemo: "Template Introduction",
    bindTemplateTip: "Confirm to bind the template",
    templateLockTip1:
      "This template has expired. Please activate the Cloud Application Billing Card and recharge the number of templates",
    templateLockTip2:
      "TIP: After activating the Cloud Application Billing Card, restore from the most recently created template. Please control the total number of your templates to avoid the template you need being unable to restore and use"
  },
  message: {
    messageCenter: "Message Center",
    alarm: "Alarm",
    setting: "Settings"
  },
  permission: {
    permissionManage: "Privilege Management",
    role: "Role",
    user: "User"
  },
  billing: {
    billingManage: "Billing Management",
    myAccount: "My Account",
    billingOverview: "Billing Overview",
    rechargeRecords: "Billing Records",
    cardType: "Billing Card Type",
    cardNumber: "Card Number",
    cardName: "Billing Card Name",
    cloudAppCard: "Cloud Application",
    remoteAccessCard: "Remote Access",
    activeOp1: "Input the last eight digits of the card number",
    activeOp2: "Scratch off the layer and enter the card code",
    activeOp3: "Turn the card over",
    activating: "Active",
    Outdated: "Outdated",
    noActivated: "Not Activated",
    activeDate: "Activated Date",
    selectActiveDate: "Select Activated Date",
    status: "Status",
    validityDate: "Valid Through",
    TagVal: "Validation of TAGs",
    opAccount: "Operation Account",
    deviceLevel: "Current level of device",
    deviceNowTag: "TAG of Device(Used/Total)",
    allocateDevice: "Allocate Device",
    allocateDate: "Allocated on",
    selectAllocateDate: "Select Allocated Date",
    allocateLevel: "Level Allocated",
    allocateTagNum: "Allocated TAG Amount",
    allocateTagStatus: "Status of TAGs Allocated",
    allocateTagVal: "Validation of TAGs Allocated",
    allocateCard: "Billing Card",
    sTagNum: "TAGs Left",
    sTemplateNum: "Templates Left",
    traffic: "Traffic",
    trafficCard: "Traffic Card",
    trafficTips: "Traffic usage reminder",
    platformGifts: "Free of Charge",
    rechargeNow: "Top up now",
    total: "Total",
    used: "Used",
    unit: "Unit",
    unit2: "Unit",
    left: "Left",

    tagAllocate: "Allocate TAGs",
    cardAllocate: "Allocate Billing Card",
    allocateTips1: "Please allocate TAGs to devices",
    allocateTips2: "Please pay attention to the period of validity of TAGs",
    allocateTips3:
      "Once a TAG is assigned to a device, the device cannot be changed throughout the valid period of the TAG.",
    viewAll: "View Detail",
    template: "Template",
    cardUseAla: "Billing Card Usage",
    allocateSuccess: "Allocated successfully",

    num: "Amount",
    notSet: "Level not assigned",
    keepNowLevel: "Keep current device level",
    allocateTips: "TAGS: {0}, Collection Interval:{1} mins",
    level: "Level",
    CollFrequency: "Data Collection Interval",
    Minutes: "Minutes",
    tagNumber: "TAG Amount",
    month: "Month",
    year: "Year",
    deviceDetail: "Device Detail",
    returnTag: "Back to TAG Allocation",
    returnDetail: "Back to TAG Details",
    deviceUnbind: "The device has been unbind from this account",
    notThisAccount: "Top-up for not this account",
    levelDesc: "Level Descriptions",
    allocateTips4: "● Free TAGs offered by the platform can only be assigned to devices with {0} level",
    allocateTips5: "● TAG allocation rules(Data collection interval is determined by the service level of device)",
    allocateTips6:
      "The service level can only be upgraded when it's to be changed. Downgrading of service level is not supported.",
    allocateTips7:
      "If the device binding account is changed, the unexpired TAG assigned to the device by the previously bound account will always follow the device",
    unallocated: "Not allocated",
    nowAllocateTagNum: "TAGs available",
    avaleBalance: "Total available",
    CurrAllocate: "Allocated this time",
    allCardNum: "Total cards",
    activedCardNum: "Active cards",
    deactivedCardNum: "Outdated cards",
    tagStatus: "TAG status",
    allocatedTagStatus: "Allocated TAG status",
    billcardPackage: "Billing packages",
    billcardActive: "Billing Card activation",
    dataStorage: "Data storage",
    validityPeriod: "Period of validity",
    dataCollFrequency: "Data Collection Interval",
    templateNum: "Temmplate amount",
    pleaseInput: "Please input",
    pleaseSelect: "Please Select",
    numTip: "Please input card number",
    passwordTip: "Please input card password",
    InputEnAndNum8: "Please input 8 alphanumeric characters",
    InputEnAndNum16: "Please input 8 alphanumeric characters",
    activedTips: "Kindly reminder",
    activedTips1: "The period of validity is one year after the Billing Card is activated",
    activedTips2: "Please use the service package within the period of validity.",
    cardNum: "Card Number",
    cardPassword: "Card Password",
    InputPassword: "Please input the 8-alphanumeric-character password.",
    InputNum: "Please enter the last 8 digits of the card number.",
    successTip: "Your Billing Card is successfully activated.",
    cardValidityDate: "Validity of Billing Card",
    successTip2: "Please use the service package within the period of validity.",
    failTip: "Failed to activate the Billing Card",
    failReasonTip: "Reason of the failed activation",
    next: "Next",
    activeNow: "Activate now",
    gotIt: "OK",
    actived: "Active",
    deActived: "Outdated",
    tagMessage:
      "There isn't enough TAGs to proceed with the allocation, please use the Cloud Application Billing Card top up now.",
    action: "Top up now",
    know: "OK",
    description1: 'The settings on this page will be applied after click "Top up now".',
    cloudApplication: "Cloud Application",
    remoteAccess: "Remote Access",
    vncTips1:
      "VNC session is active. Regarding network traffic usage, please use it appropriately according to your needs.",
    disabledTemplates: "There are {0} unusable templates now.",
    tagInfo: "TAG information",
    requirements1: "To make sure TAGs of the template are usable",
    requirements2:
      "please guarantee the number of TAGs allocated to the device is equal or bigger than the number of the device's TAGs.",
    deviceTagCount: "Usable TAGs of the device",
    templateTagCount: "Number of Template's TAG",
    vncTips2: "Viewing the HMI screen remotely will consume network traffics, continue?",
    vncEnd: "VNC session stops.",
    timeLimitReached:
      "You have set a time limit for the VNC session. The time has expired, and the session has been automatically closed.",
    vncTimeSetting: "Max VNC session duration",
    instruction: "When the max duration of a VNC session is reached. The session will be terminated.",
    tagAction: "Max duration",
    trafficTips1: "The network traffics bundled with the device are not enough, please top up if you need to use it.",
    // trafficTips2: "The network traffics bundled with the device are not enough, please top up if you need to use it.",
    trafficTips2:
      "The current device has insufficient traffic, please avoid using traffic-related transmission services.",
    trafficTips3: 'Please go to "Billing Management -- Billings" to check the statistics of network traffics.',
    trafficTips4: "The device has been deleted",
    trafficTips5: "VNC usage has ended, the device has insufficient traffic",

    selectCardType: "Please select the type of the card",
    trafficTips6: "The unallocated Remote Access card can be allocated at {0}",
    myCount: "My account-Topup card allocation",
    activate: "Activate",
    trafficTips7:
      "The services associated with the card can be used after being allocated to devices, please use the services within the valid period.",
    notAllocate: "Don't allocate temporarily",
    billingCard: "Billing Card",
    trafficTips8: "Not changeable after allocated to devices",
    trafficTips9: "please conduct allocation with caution.",
    trafficTips10: "The traffics that expires first will be used first.",
    confirmAllocationTips: "Allocate services to {0}?",
    confirmAllocation: "Confirm allocation",
    trafficPeriod: "Valid period of Traffics",
    billingCardTraffic: "Traffics of the billing card",
    trafficTotal: "Total traffics",
    trafficUsed: "Traffics used",
    trafficLeft: "Traffics left",
    trafficStatus: "The status of traffics left",
    billingCardAllocate: "Billing card allocation ",
    trafficLeftCard: "Traffics left of the card",
    trafficLeftDevice: "Traffics left of the device",
    billingCardUsage: "Usuage of billing card",
    trafficStatistics: "Statistics of valid traffics",
    trafficsLeftValid: "Valid traffics left",
    trafficsLeftTotal: "Total valid traffics",
    selectDateActive: "Select Activated Date",
    selectDateUsed: "Select Used Date",
    DateofUse: "Date of Use",
    trafficUsed2: "Traffics used",
    trafficUsage: "Traffics Usuage"
  },
  profile: {
    personCenter: "Personal Settings"
  },
  ERR_FR_TOO_MANY_REDIRECTS: "Too many redirects",
  ERR_BAD_OPTION_VALUE: "Invalid Parameter Value",
  ERR_BAD_OPTION: "Invalid Parameter Value",
  ERR_NETWORK: "Network Error",
  ERR_DEPRECATED: "Deprecated",
  ERR_BAD_RESPONSE: "Bad Response",
  ERR_BAD_REQUEST: "Bad Request",
  ERR_NOT_SUPPORT: "Not supported",
  ERR_INVALID_URL: "Invalid URL",
  ERR_CANCELED: "Request is canceled",
  ECONNABORTED: "Request timeout or canceled",
  ETIMEDOUT: "Request timeout",
  ERR_EMAIL_EXIST: "Email address already exists",
  E_INVALID_ID: "Invalid ID",
  E_GET_DEV_GROUP_DETAIL_ERR: "Failed to get device details",
  E_USER_NOT_FOUNT: "The user is not found",
  E_DEVICE_VNC_OFFLINE: "The VNC service of the device isn't available",
  E_MISSING_AUTH_HEADER: "Header of the request is lost",
  E_TEMPLATE_CAN_NOT_DEL_WITH_BIND_DEV: "Devices are binded with this template",
  E_INVALID_PASSWD: "Incorrect Password",
  E_INPUT_PWD_ERR: "Password Input Error",
  E_INVALID_OR_EXPIRED_TOKEN: "Login has expired, please log in again!",
  E_USER_NOT_FOUND: "User does not exist",
  ERR_PHONE_NOT_VERIFY: "Verification code error",
  ERR_ACCOUNT_EXIST: "Account already exists",
  ERR_EMAIL_NOT_ACTIVE: "Email is not activated",
  E_BILLING_TAG_RECORD_FOUND: "The card number or password is wrong. Please check your card number or password.",
  E_CLOUD_CARD_PWD_ERR: "The card number or password is wrong. Please check your card number or password.",
  E_BILLING_REMOTE_ACCESS_RECORD_FOUND:
    "The card number or password is wrong. Please check your card number or password.",
  "1001": "Authentication Error",
  "1002": "Authorization Error",
  "1003": "No Data Found",
  "1004": "Incorrect Password",
  "1005": "Database Error",
  "1006": "Input Parameter Error",
  "1007": "Server Error",
  "1008": "Auth Header Lost",
  "1009": "Authentication Expired",
  "1010": "User Not Found",
  "1011": "Verification Code Error",
  "1012": "User Already Existed",
  "1013": "Failed to send the email",
  "1014": "Please go to the registered email address to activate your account",
  "1015": "Email Limits Reached",
  "1016": "Mobile Phone Not Verified.",
  "1017": "Please verify the mobile phone again.",
  "1018": "Failed to send verification code",
  "1019": "Account Not Matched",
  "1020": "Activation link expired, please register again",
  "1021": "Json format is incorrect.",
  "1022": "Activation code expired",
  "1023": "The current client's session information is not found. Resend RAND",
  "1024": "Please go to HMI to open VNC",
  "1025": "Illegal PIN",
  "1026": "Illegal License",
  "1027": "Illegal group ID",
  "1028": "Illegal HMI SN",
  "1029": "Illegal Template ID",
  "1030": "Name of the template already exists",
  "1031": "Email Not Verified",
  "1032": "The Group or its Sub-Groups exist on devices, so can't be deleted",
  "1033": "The number of templates has reached the upper limit",
  "1034": "Device name already exists",
  "1037": "This template has associated devices and cannot be deleted",
  "1043": "The card number or password is wrong. Please check your card number or password.",
  "1045": "The biliing card has been activated, please do not activate it again",
  "1050": "The biliing card has been activated, please do not activate it again",
  "1055": "Account has been deleted",
  "2001": "Failed to parse DIAView TAR",
  "2003": "Network Error"
}
